import React from 'react';
import Reveal from 'react-reveal/Reveal';
import PricingOption from '../../components/About/FAQ/pricingOption';
import FAQItem from '../../components/About/FAQ/FAQItem';
import SecTitle from '../../components/Title/Sectitle';
import faqData from '../../data/faqData';

const Faq = () => (
  <div>
    <section className="sec_pad bg_color">
      <div className="container custom_container">
        <SecTitle
          sClass="sec_title text-center mb_70"
          Title="What is your pricing?"
          tClass="t_color3"
          TitleP="The challenges our clients face are unique and require custom solutions. Total costs depend on many variables within your scope. To get a real understanding of costs we’ll need to hold an introductory call to learn more about your project. To give you an understanding of what your costs will be based on, our rates start at $100/hr."
        />
        <div className="row mb_30">
          {
            faqData.pricingOptions.map((items) => (
              <Reveal effect="fadeInUp" key={items.id}>
                <div className="col-lg-4 col-sm-6">
                  <PricingOption
                    iShap={items.shape}
                    Sicon={items.icon}
                    text={items.text}
                    description={items.description}
                    btn={items.btn}
                    bicon={items.bicon}
                    descriptionList={items.descriptionList}
                    deliverables={items.deliverables}
                    key={items.id}
                  />
                </div>
              </Reveal>
            ))
          }
        </div>
        <div className="features_info">
          {faqData.questions.map((item, i) => (
            <FAQItem
              ftitle={item.title}
              descriptions={item.description}
              key={i}
            />
          ))}
        </div>
      </div>
    </section>
  </div>
);

export default Faq;
