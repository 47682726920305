import React, { Component } from 'react';
import Reveal from 'react-reveal/Reveal';

class FAQitems extends Component {
  render() {
    const {
      ftitle, descriptions, aClass,
    } = this.props;
    return (
      <div className="agency_featured_item row">
        <div>
          <div className="agency_featured_content wow fadeInLeft pr_70 pl_70" data-wow-delay="0.6s">
            <Reveal effect="fadeInRight">
              <div className="dot">
                <span className="dot1" />
                <span className="dot2" />
              </div>
              <h3>{ftitle}</h3>
              <p>{descriptions}</p>
            </Reveal>
          </div>
        </div>
      </div>
    );
  }
}

export default FAQitems;
