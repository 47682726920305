import React, { Component } from 'react';

class PricingOption extends Component {
  render() {
    const {
      iShap, Sicon, text, description, nClass, descriptionList, deliverables,
    } = this.props;
    return (
      <div className={`p_service_item agency_service_item pr_70 wow fadeInUp ${nClass}`}>
        <h5 className="f_600 f_p t_color3">{text}</h5>
        <div className="row mx-0">
          <div className="icon w-25">
            <img src={require(`../../../img/home4/${iShap}`)} alt="" />
            <i className={Sicon} />
          </div>
          <div className="w-75">
            <ul style={{ listStyleType: 'none' }}>
              {descriptionList.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
        <p>{description}</p>
        <h5>Deliverables</h5>
        <ul>
          {deliverables.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      </div>
    );
  }
}
export default PricingOption;
